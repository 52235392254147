import { singleton } from '~/decorators/dependency-container'

@singleton()
export class UrlValidator {
  trustedUrlPattern: RegExp = /^https*:\/\/(.+\.car\.gr|car\.gr)/
  anyPattern: RegExp = /[^]*/

  isTrustedUrl(url: string): boolean {
    return this.trustedUrlPattern.test(url)
  }
}
