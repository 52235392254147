













import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { StringMap } from '~/models/shared/types'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { UrlValidator } from '~/services/url/UrlValidator'
import HeadService from '~/services/HeadService'
import DealerSiteLayoutService from '~/services/dealers/site/DealerSiteLayoutService'
import CExternalLinkMediatorModal from '~/components/shared/configurable/modal/CExternalLinkMediatorModal.vue'

export default Vue.extend({
  components: { CExternalLinkMediatorModal },
  data() {
    return {
      externalLinkMediatorUrl: '',
      showExternalLinkMediator: false,
      body: undefined
    }
  },
  computed: {
    ...mapGetters(DEALER_SITE_NS, [
      'activePage',
      'getTemplateOption',
      'componentShouldRenderPage',
      'shouldAnimatePageTransition'
    ]),
    ...mapState(DEALER_SITE_NS, {
      siteTitle: 'title',
      customPageBodies: 'customPageBodies'
    }),
    title(): string | undefined {
      return this.activePage?.name
    }
  },
  watch: {
    customPageBodies(value: StringMap<string>) {
      this.setBody(value.get(this.activePage.id))
    }
  },
  mounted() {
    this.setBody(this.activePage.body)
  },
  methods: {
    setBody(html: string) {
      this.body = ['null', 'undefined'].includes(html) ? '' : html

      this.$nextTick(() => {
        this.updateBodyChildrenEventListeners()
      })
    },
    updateBodyChildrenEventListeners() {
      if (!this.$refs.body) {
        return
      }
      for (const anchor of this.$refs.body.querySelectorAll('a')) {
        const urlValidatorService = this.$dep(UrlValidator)
        if (urlValidatorService.isTrustedUrl(anchor.href)) {
          return
        }
        anchor.addEventListener('click', e => {
          // Listener is registered on element node, therefore it will be cleared once the node is removed from DOM.
          e.preventDefault()
          this.externalLinkMediatorUrl = anchor.href
          this.showExternalLinkMediator = true
        })
      }
    }
  },
  head() {
    const [headService, dealerSiteLayoutService] = this.$deps(
      HeadService,
      DealerSiteLayoutService
    )
    const head = headService.createHead()
    dealerSiteLayoutService.setPageTitle(head, this.title)
    return head
  }
})
